<template>
  <validation-observer ref="observer" @scroll="checkActiveSection">
    <v-container v-if="dataLoading">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <form v-else @submit.prevent="create">
      <div class="task-container pa-9 pa-md-6 pa-lg-9">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h3 class="page-title font-weight-bold">タスクの新規登録</h3>
            </v-col>
          </v-row>
          <v-row id="customerPart" ref="customerPart">
            <v-col cols="12">
              <div
                class="text-title text-title-2 text-heading-2 font-weight-bold"
              >
                顧客 / 台帳
              </div>
              <div class="horizontal-line"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="form-content">
                <customer
                  :task="task"
                  :expanded.sync="expanded"
                  :loading="searchCustomerLoading"
                  :searched="searched"
                  @update:search-input="findCustomer"
                  @update:customer="onCustomerChange"
                ></customer>
              </div>
            </v-col>
          </v-row>

          <v-row id="dateTimePart" ref="dateTimePart" class="mb-6">
            <v-col cols="12" class="mb-2">
              <div
                class="text-title text-title-2 text-heading-2 font-weight-bold"
              >
                日時
              </div>
              <div class="horizontal-line"></div>
            </v-col>
            <v-col cols="12">
              <div class="form-content">
                <date-time :task="task" color="coat"></date-time>
              </div>
            </v-col>
          </v-row>

          <v-row id="memoPart" ref="memoPart" class="mb-6">
            <v-col cols="12" class="mb-2">
              <div
                class="text-title text-title-2 text-heading-2 font-weight-bold"
              >
                メモ
              </div>
              <div class="horizontal-line"></div>
            </v-col>
            <v-col cols="12">
              <div class="form-content">
                <memo
                  :normal.sync="task.memo"
                  :important.sync="task.important_notice"
                ></memo>
              </div>
            </v-col>
          </v-row>

          <v-row id="transportationPart" ref="transportationPart">
            <v-col cols="12" class="mb-2">
              <div
                class="text-title text-title-2 text-heading-2 font-weight-bold"
              >
                チーム / 車
              </div>
              <div class="horizontal-line"></div>
            </v-col>
            <v-col cols="12">
              <div class="form-content">
                <transportation :task="task"></transportation>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div
        class="create-task-footer px-6 px-sm-3 px-lg-6"
        :class="!isIntersecting ? 'shadow' : ''"
      >
        <v-row>
          <v-col offset="2" cols="5">
            <v-btn
              class="mr-3 mr-sm-2 mr-lg-3"
              :color="isSectionActive('customer')"
              depressed
              rounded
              @click="$vuetify.goTo('#customerPart', { offset: offset })"
            >
              顧客 / 台帳
            </v-btn>
            <v-btn
              class="mr-3 mr-sm-2 mr-lg-3"
              :color="isSectionActive('dateTime')"
              depressed
              rounded
              @click="$vuetify.goTo('#dateTimePart', { offset: offset })"
            >
              日時
            </v-btn>
            <v-btn
              class="mr-3 mr-sm-2 mr-lg-3"
              :color="isSectionActive('memo')"
              depressed
              rounded
              @click="$vuetify.goTo('#memoPart', { offset: offset })"
            >
              メモ
            </v-btn>
            <v-btn
              :color="isSectionActive('transportation')"
              depressed
              rounded
              @click="$vuetify.goTo('#transportationPart', { offset: offset })"
            >
              チーム / 車
            </v-btn>
          </v-col>

          <v-col cols="5" class="d-flex justify-end">
            <v-btn
              class="mr-3 mr-sm-2 mr-lg-3"
              :to="{ name: 'ScheduleMonthly' }"
              text
            >
              キャンセル
            </v-btn>
            <v-btn
              class="mr-3 mr-sm-2 mr-lg-3"
              outlined
              color="#2F80ED"
              style="display:none"
            >
              <v-icon size="32" left>$sendMail</v-icon>
              通知
            </v-btn>
            <v-btn
              color="#4F55A7"
              class="white--text"
              width="100"
              :loading="loading.all"
              @click="create"
              >登録
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import Customer from '@/components/admin/partials/Task/Create/Customer'
import DateTime from '@/components/admin/partials/Task/Create/DateTime'
import Memo from '@/components/admin/partials/Task/Create/Memo'
import Transportation from '@/components/admin/partials/Task/Create/Transportation'
import { debounce } from 'lodash'
import {mapGetters, mapMutations} from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'Create',
  components: { Transportation, Memo, DateTime, Customer },
  data() {
    return {
      task: {
        customer: null,
        site: null,
        project: null,
        name: '',
        task_start_time: '',
        gathering_time: '',
        gathering_place: '',
        date: dayjs().format('YYYY-MM-DD'),
        time: 0,
        leader_id: null,
        car_id: null,
        priority: 0,
        gasoline_card: 0,
        ETC_card: 0,
        project_id: null,
        service_type_id: null,
        suspense_payment: null,
        task_freelancer_users: null,
        task_start_date: null,
        task_type_id: null,
        //notified_at: dayjs().format('YYYY-MM-DD'),
        selectedGeneral: [],
        selectedCleaning: [],
        selectedPartners: []
      },
      offset: 75,
      expanded: false,
      isIntersecting: false,
      btnActive: 'rgba(79, 85, 167, 0.45)',
      btnRegular: 'rgba(79, 85, 167, 0.1)',
      customers: [],
      searchCustomerLoading: false,
      intersectedSection: 'customerPart',
      loading: {
        all: false,
        work_info: true,
        claim: true,
        photos: true,
        button: false
      },
      on: 'customerPart',
      searched: false,
      dataLoading: false
    }
  },

  computed: {
    ...mapGetters(['allCustomers', 'allPartners']),

    sectionsScrollYValues() {
      return []
    }
  },

  async mounted() {
    window.addEventListener('scroll', this.updateScroll)
    this.dataLoading = true
    await this.getCarData()
    await this.getUserData()
    await this.getPartners()
    this.dataLoading = false
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },

  methods: {
    ...mapMutations(['showModal']),

    findCustomer: debounce(function(query) {
      if (query) {
        this.searched = true
        this.task.site = null
        this.searchCustomerLoading = true
        let params = {
          search: query
        }
        this.$store.dispatch('CUSTOMER_GET_ALL', params).finally(() => {
          this.searchCustomerLoading = false
        })
      }
    }, 1000),
    create() {
      let employees = this.task.selectedGeneral
        .concat(this.task.selectedCleaning)
        .map(item => item.id)

      if (employees.length === 0 || this.task.leader_id === null) {
        this.showModal({
          text: '変更内容を保存しますか？',
          action: {
            process: {
              color: 'red',
              text: '続行する'
            },
            cancel: {
              color: 'secondary',
              text: 'キャンセル'
            }
          },
          func: this.processCreate
        })
        return
      }
      this.processCreate()
    },
    processCreate() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.loading.all = true
        this.task.customer_id = this.task.customer.id
        this.task.site_id = this.task.site.id
        this.task.project_id = this.task.project.id
        this.task.service_type_id = this.task.project.service_type_id
        this.task.task_start_date = this.task.date

        this.task.task_members = this.task.selectedGeneral
          .concat(this.task.selectedCleaning)
          .map(item => item.id)

        this.task.selectedPartners = this.task.selectedPartners.filter(
          partner => partner.freelancers_count
        )

        this.task.partners = this.task.selectedPartners.map(partner => {
          return {
            id: partner.id,
            freelancers_count: partner.freelancers_count
          }
        })
        this.$store
          .dispatch('CREATE_TASK', this.task)
          .then(
            response => {
              if (response.data.status === 'success') {
                this.$router.push({
                  path: '/schedule/task-view',
                  query: {
                    status: 'success',
                    message: 'タスクを作成しました。',
                    date: this.task.date,
                    task: response.data.data.task.id,
                  }
                })
              }
            },
            error => {
              throw error
            }
          )
          .finally(() => {
            this.loading.all = false
          })
      })
    },

    checkActiveSection() {},

    isSectionActive(section) {
      return this.on == `${section}Part` ? this.btnActive : this.btnRegular
    },

    updateScroll() {
      let scrollPosition = window.scrollY
      this.on = 'customerPart'
      if (
        scrollPosition >=
        this.$refs.transportationPart?.offsetTop - this.offset
      ) {
        this.on = 'transportationPart'
      } else if (
        scrollPosition >=
        this.$refs.memoPart?.offsetTop - this.offset
      ) {
        this.on = 'memoPart'
      } else if (
        scrollPosition >=
        this.$refs.dateTimePart?.offsetTop - this.offset
      ) {
        this.on = 'dateTimePart'
      } else if (
        scrollPosition >=
        this.$refs.customerPart?.offsetTop - this.offset
      ) {
        this.on = 'customerPart'
      }
    },

    fetchTaskClaims() {
      let id = this.task.customer.id
      this.$store.dispatch('CLAIMS_BY_TASK_ID', id)
      this.loading.claim = false
    },
    fetchTaskImages() {
      let param = {
        photo: {
          id: this.task.customer.id,
          site_id: this.task.site.id,
          paginate: 10
        }
      }
      this.$store.dispatch('GET_ALL_PHOTO_BY_CUSTOMER_ID', param.photo)
      this.loading.photos = false
    },
    onCustomerChange() {
      if (this.task.customer) {
        this.fetchTaskClaims()
        this.fetchTaskImages()
      }
    },

    async getUserData() {
      await this.$store.dispatch('USER_GET_ALL')
    },

    async getCarData() {
      await this.$store.dispatch('CAR_GET_ALL')
    },

    async getPartners() {
      let param = {
        partner: {
          type: 3
        }
      }
      await this.$store.dispatch('PARTNER_GET_ALL', param.partner)
    }
  }
}
</script>

<style src="./Create.scss" lang="scss" scoped></style>
