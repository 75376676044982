<template>
  <div class="claim-box mt-4 pa-8">
    <v-row>
      <v-col cols="12" class="claim-box-headline">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon class="mr-1" color="#C62E2E" size="6">$dot</v-icon>
            クレーム
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn
              target="_blank"
              :to="{
                name: 'CustomerViewDetailClaim',
                params: {
                  customer_id: task.customer.id,
                  site_id: task.site.id
                }
              }"
              rounded
              color="white"
            >
              一覧
              <v-icon right size="24"> $newTab </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center" v-if="loading">
        <v-progress-circular size="32" indeterminate />
      </v-col>
      <v-col v-else cols="10" offset="2" class="flex-grow-1">
        <v-row>
          <template v-if="claims.length > 0">
            <v-col
                v-for="(claim, index) in claims"
                :key="`claim-${index}`"
                cols="12"
            >
              <v-row align="center">
                <v-col cols="2" class="claim-box-date">
                  {{ claim.created_at | formatDate() }}
                </v-col>
                <v-col cols="3" class="claim-box-tag mr-14">
                  <v-chip
                    color="#8C5F5F"
                    text-color="white"
                    v-for="(category, index) in claim.categories"
                    :key="index"
                    label
                    small
                    class="mr-3 claim-tag"
                  >
                    {{ category.name }}
                  </v-chip>
                </v-col>
                <v-col cols="6" class="claim-box-content">
                  {{ claim.description }}
                </v-col>
              </v-row>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12">
              クレーム登録はありません。
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs"
import { mapGetters } from "vuex";
export default {
  name: 'Claim',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    customerid: {
      type: Number,
      required: false
    },
    task: {
      type: Object,
      required: false
    }
  },
  filters: {
    formatDate(value) {
      return dayjs(value).format('YYYY年 M月 D日')
    }
  },
  computed: {
    ...mapGetters(["allClaimCategories"]),
    claims() {
      return this.$store.getters.allCustomerclaims;
    }
  },
  created() {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      let param = {
        claim: {
          customer_id: this.customerid,
          site_id: this.task.site.id,
          paginate: 10
        }
      }
      await this.$store.dispatch('CUSTOMER_GET_ALL_CLAIM', param.claim)
    }
  }
}
</script>

<style lang="scss" src="./Claim.scss" scoped></style>
