export default [
  {
    name: 'floor',
    label: '共用部洗浄<br />床洗浄ワックス',
    fields: [
      {
        name: 'prep',
        label: '清掃前準備'
      },
      {
        name: 'detergent',
        label: '使用洗剤'
      },
      {
        name: 'detergent_method',
        label: '洗剤塗布方法'
      },
      {
        name: 'cleaning_method',
        label: '基本洗浄方法'
      },
      {
        name: 'polisher',
        label: '使用ポリッシャー'
      },
      {
        name: 'cleaning_machine',
        label: '使用自洗機'
      },
      {
        name: 'pat',
        type: 'color',
        label: '使用パット'
      },
      {
        name: 'certain_place_method',
        label: '場所指定洗浄方法'
      },
      {
        name: 'certain_place_explanation',
        label: '指定場所'
      },
      {
        name: 'divider'
      },
      {
        name: 'waxing_place',
        label: 'ワックス塗布階数'
      },
      {
        name: 'waxing_product',
        label: '使用ワックス'
      },
      {
        name: 'waxing_rule',
        label: '注意事項'
      }
    ]
  },
  {
    name: 'window',
    label: '窓ガラス清掃',
    fields: [
      {
        name: 'stepladder',
        label: '使用脚立'
      },
      {
        name: 'extension_pole',
        label: '延長ポール'
      },
      {
        name: 'safety_helmet',
        label: 'ヘルメット'
      },
      {
        name: 'safety_belt',
        label: '安全帯'
      },
      {
        name: 'outside',
        label: '外面'
      },
      {
        name: 'inside',
        label: '内面'
      },
      {
        name: 'certain_window_method',
        label: '場所指定特記事項'
      },
      {
        name: 'certain_window_explanation',
        label: '指定場所'
      },
      {
        name: 'certain_window_rule',
        label: '注意事項'
      }
    ]
  },
  {
    name: 'carpet',
    label: 'カーペット洗浄',
    fields: [
      {
        name: 'prep',
        label: '作業前工程'
      },
      {
        name: 'pre_task',
        label: '前処理作業'
      },
      {
        name: 'washing',
        label: '洗浄作業'
      },
      {
        name: 'machine',
        label: '使用バルチャー'
      },
      {
        name: 'stain_removal',
        label: '染み抜き'
      },
      {
        name: 'wastewater',
        label: '汚水回収'
      }
    ]
  }
]
