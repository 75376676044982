var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "form-header",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 通常メモ ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "normal",
      "rules": "max:1000"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-textarea', {
          staticClass: "form-text memo-normal",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "自由入力",
            "counter": "",
            "maxlength": "1000",
            "rows": "3",
            "error-messages": errors,
            "error": errors.length !== 0
          },
          scopedSlots: _vm._u([{
            key: "counter",
            fn: function fn(_ref2) {
              var props = _ref2.props;
              return [_vm._v(" " + _vm._s(props.value) + " / " + _vm._s(props.max) + " 文字 ")];
            }
          }], null, true),
          model: {
            value: _vm._normal,
            callback: function callback($$v) {
              _vm._normal = $$v;
            },
            expression: "_normal"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "form-header",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 重要連絡 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "important",
      "rules": "max:500"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-textarea', {
          staticClass: "form-text memo-important",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "自由入力",
            "counter": "",
            "maxlength": "500",
            "background-color": "#FFEAEA",
            "rows": "3",
            "error-messages": errors,
            "error": errors.length !== 0
          },
          scopedSlots: _vm._u([{
            key: "counter",
            fn: function fn(_ref4) {
              var props = _ref4.props;
              return [_vm._v(" " + _vm._s(props.value) + " / " + _vm._s(props.max) + " 文字 ")];
            }
          }], null, true),
          model: {
            value: _vm._important,
            callback: function callback($$v) {
              _vm._important = $$v;
            },
            expression: "_important"
          }
        })];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }