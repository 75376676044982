<template>
  <div>
    <v-row>
      <v-col cols="auto" class="form-header">
        通常メモ
      </v-col>
      <v-col cols="auto" class="flex-grow-1">
        <validation-provider v-slot="{ errors }" name="normal" rules="max:1000">
          <v-textarea
            v-model="_normal"
            class="form-text memo-normal"
            dense
            outlined
            placeholder="自由入力"
            counter
            maxlength="1000"
            rows="3"
            :error-messages="errors"
            :error="errors.length !== 0"
          >
            <template v-slot:counter="{ props }">
              {{ props.value }} / {{ props.max }} 文字
            </template>
          </v-textarea>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="form-header">
        重要連絡
      </v-col>
      <v-col cols="auto" class="flex-grow-1">
        <validation-provider
          v-slot="{ errors }"
          name="important"
          rules="max:500"
        >
          <v-textarea
            v-model="_important"
            class="form-text memo-important"
            dense
            outlined
            placeholder="自由入力"
            counter
            maxlength="500"
            background-color="#FFEAEA"
            rows="3"
            :error-messages="errors"
            :error="errors.length !== 0"
          >
            <template v-slot:counter="{ props }">
              {{ props.value }} / {{ props.max }} 文字
            </template>
          </v-textarea>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Memo',
  props: {
    normal: {
      type: String,
      default: '',
      required: false
    },
    important: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    _normal: {
      get() {
        return this.normal
      },
      set(newValue) {
        this.$emit('update:normal', newValue)
      }
    },
    _important: {
      get() {
        return this.important
      },
      set(newValue) {
        this.$emit('update:important', newValue)
      }
    }
  }
}
</script>

<style lang="scss" src="./Memo.scss" scoped></style>
