<template>
  <v-dialog
    persistent
    max-width="600"
    :value="visibility"
    @keydown.esc="$emit('update:visibility', false)"
  >
    <v-card>
      <v-card-title>
        <v-tabs
          style="max-width: 300px"
          v-model="tab"
          background-color="transparent"
        >
          <v-tab class="tab-title" v-for="item in daySlots" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-btn
          icon
          text
          color="black"
          @click="$emit('update:visibility', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in daySlots" :key="item">
            <v-data-table
              :loading="loading"
              :headers="tableHeaders"
              :items="item === dayLabel ? dayTimeCars : nightTimeCars"
              :height="300"
              fixed-header
              dense
              disable-pagination
              disable-sort
              disable-filtering
              hide-default-footer
              @click:row="handleClick"
            >
              <template v-slot:item.action="{ item: car }">
                <v-radio-group v-model="selectedCar">
                  <v-radio
                    :ref="`radio-${car.id}`"
                    :disabled="!!car.meeting_time.length"
                    :value="`${car.id}-${item}`"
                    @click.stop
                  >
                    {{ car.name }}
                  </v-radio>
                </v-radio-group>
              </template>
              <template v-slot:item.meeting_time="{ item }">
                <template v-if="!item.meeting_time.length">
                  未使用
                </template>
                <template v-else>
                  <div
                    v-for="(meetingTime, index) in item.meeting_time"
                    :key="`meetingTime-${meetingTime}-${index}`"
                    class="py-1"
                  >
                    {{ meetingTime }}
                  </div>
                </template>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('click:cancel')">
          キャンセル
        </v-btn>
        <v-btn
          class="white--text"
          color="#4F55A7"
          :loading="loading"
          @click="submit"
        >
          適用
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/plugins/dayjs'

export default {
  name: 'CarAvailabilityModal',
  props: ['visibility', 'loading', 'date', 'time'],
  computed: {
    ...mapGetters(['availableCars']),
    dayTimeCars() {
      return this.availableCars?.daytime?.map(item => {
        return {
          id: item.id,
          name: item.car_class,
          meeting_time: item.tasks.map(task => {
            return `${task.gathering_time} 集合@${task.gathering_place || '未登録'} (${task.site?.prefecture?.name || '未登録'}${task.site?.city || '未登録'})`
          })
        }
      })
    },
    nightTimeCars() {
      return this.availableCars?.nighttime?.map(item => {
        return {
          id: item.id,
          name: item.car_class,
          meeting_time: item.tasks.map(task => {
            return `${task.gathering_time} 集合@${task.gathering_place || '未登録'} (${task.site?.prefecture?.name || '未登録'}${task.site?.city || '未登録'})`
          })
        }
      })
    },
    dayLabel() {
      return `日中 (${this.date})`
    },
    daySlots() {
      return [
        this.dayLabel, '18時以降'
      ]
    }
  },
  watch: {
    visibility(val) {
      if (val) {
        this.tab = this.time
      }
    }
  },
  data() {
    return {
      selectedCar: '',
      tab: 0,
      tableHeaders: [
        { text: '', value: 'action', width: '50' },
        { text: '車両名', value: 'name', width: '150' },
        { text: '空状況/集合時間', value: 'meeting_time' }
      ]
    }
  },
  methods: {
    submit() {
      this.$emit('click:submit', this.selectedCar.split('-')[0])
      this.$emit('update:visibility', false)
      this.selectedCar = ''
    },
    handleClick($event) {
      this.$refs[`radio-${$event.id}`][0].toggle()
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-title{
  font-size: 1.4rem;
  font-weight: 700;
}
::v-deep {
  .v-data-table {
    &__wrapper {
      table {
        tbody, thead, tfoot {
          tr {
            th{
              font-size: 1.4rem;
            }
            td {
              font-size: 1.4rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
