var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    on: {
      "scroll": _vm.checkActiveSection
    }
  }, [_vm.dataLoading ? _c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1)], 1)], 1) : _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "task-container pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v("タスクの新規登録")])])], 1), _c('v-row', {
    ref: "customerPart",
    attrs: {
      "id": "customerPart"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2 font-weight-bold"
  }, [_vm._v(" 顧客 / 台帳 ")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-content"
  }, [_c('customer', {
    attrs: {
      "task": _vm.task,
      "expanded": _vm.expanded,
      "loading": _vm.searchCustomerLoading,
      "searched": _vm.searched
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expanded = $event;
      },
      "update:search-input": _vm.findCustomer,
      "update:customer": _vm.onCustomerChange
    }
  })], 1)])], 1), _c('v-row', {
    ref: "dateTimePart",
    staticClass: "mb-6",
    attrs: {
      "id": "dateTimePart"
    }
  }, [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2 font-weight-bold"
  }, [_vm._v(" 日時 ")]), _c('div', {
    staticClass: "horizontal-line"
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-content"
  }, [_c('date-time', {
    attrs: {
      "task": _vm.task,
      "color": "coat"
    }
  })], 1)])], 1), _c('v-row', {
    ref: "memoPart",
    staticClass: "mb-6",
    attrs: {
      "id": "memoPart"
    }
  }, [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2 font-weight-bold"
  }, [_vm._v(" メモ ")]), _c('div', {
    staticClass: "horizontal-line"
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-content"
  }, [_c('memo', {
    attrs: {
      "normal": _vm.task.memo,
      "important": _vm.task.important_notice
    },
    on: {
      "update:normal": function updateNormal($event) {
        return _vm.$set(_vm.task, "memo", $event);
      },
      "update:important": function updateImportant($event) {
        return _vm.$set(_vm.task, "important_notice", $event);
      }
    }
  })], 1)])], 1), _c('v-row', {
    ref: "transportationPart",
    attrs: {
      "id": "transportationPart"
    }
  }, [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2 font-weight-bold"
  }, [_vm._v(" チーム / 車 ")]), _c('div', {
    staticClass: "horizontal-line"
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-content"
  }, [_c('transportation', {
    attrs: {
      "task": _vm.task
    }
  })], 1)])], 1)], 1)], 1), _c('div', {
    staticClass: "create-task-footer px-6 px-sm-3 px-lg-6",
    class: !_vm.isIntersecting ? 'shadow' : ''
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "offset": "2",
      "cols": "5"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3 mr-sm-2 mr-lg-3",
    attrs: {
      "color": _vm.isSectionActive('customer'),
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#customerPart', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" 顧客 / 台帳 ")]), _c('v-btn', {
    staticClass: "mr-3 mr-sm-2 mr-lg-3",
    attrs: {
      "color": _vm.isSectionActive('dateTime'),
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#dateTimePart', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" 日時 ")]), _c('v-btn', {
    staticClass: "mr-3 mr-sm-2 mr-lg-3",
    attrs: {
      "color": _vm.isSectionActive('memo'),
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#memoPart', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" メモ ")]), _c('v-btn', {
    attrs: {
      "color": _vm.isSectionActive('transportation'),
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#transportationPart', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" チーム / 車 ")])], 1), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "5"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3 mr-sm-2 mr-lg-3",
    attrs: {
      "to": {
        name: 'ScheduleMonthly'
      },
      "text": ""
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    staticClass: "mr-3 mr-sm-2 mr-lg-3",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "outlined": "",
      "color": "#2F80ED"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "32",
      "left": ""
    }
  }, [_vm._v("$sendMail")]), _vm._v(" 通知 ")], 1), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "width": "100",
      "loading": _vm.loading.all
    },
    on: {
      "click": _vm.create
    }
  }, [_vm._v("登録 ")])], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }