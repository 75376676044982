<template>
  <div>
    <CarAvailability
      :visibility.sync="carAvailabilityModal.visibility"
      :loading="carAvailabilityModal.loading"
      :date="task.date"
      :time="task.time"
      @click:cancel="carAvailabilityModal.visibility = false"
      @click:submit="selectCar"
    />
    <!--    <div class="pt-6 px-8 pb-8 mb-8 header-label">-->
    <!--      <v-snackbar v-model="autoLoadAvailableWorkerError" :timeout="3000">-->
    <!--        サーバーが30秒以上レスポンスを返さないため一旦停止します。-->
    <!--      </v-snackbar>-->
    <!--      <div class="text-heading-2 font-weight-medium mb-6">-->
    <!--        {{ workerListLabel }}-->
    <!--      </div>-->
    <!--      <v-col cols="12" v-if="!loadAvailableWorkers">-->
    <!--        <v-btn-->
    <!--          color="#4F55A7"-->
    <!--          class="white&#45;&#45;text"-->
    <!--          @click="changeAvailableWorkerAutoLoadState()"-->
    <!--        >-->
    <!--          空き状況を検索する-->
    <!--        </v-btn>-->
    <!--        <div class="mt-4">-->
    <!--          サーバーの稼働状況によっては表示に時間がかかります。-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--      <template v-else>-->
    <!--        <v-col class="text-center" v-if="task.date && !availableWorkersLoaded">-->
    <!--          <v-progress-circular indeterminate></v-progress-circular>-->
    <!--        </v-col>-->
    <!--        <div v-if="task.date && availableWorkersLoaded">-->
    <!--          <v-row align="center">-->
    <!--            <v-col-->
    <!--              cols="1"-->
    <!--              class="text-right employee-list-label text-heading-2"-->
    <!--            >-->
    <!--              昼-->
    <!--            </v-col>-->
    <!--            <v-col cols="11">-->
    <!--              <v-row dense>-->
    <!--                <v-col-->
    <!--                  cols="auto"-->
    <!--                  v-for="(worker, index) in availableDayWorkers"-->
    <!--                  :key="`day-worker-${index}`"-->
    <!--                  class="text-center available-worker-item"-->
    <!--                >-->
    <!--                  <v-avatar size="25">-->
    <!--                    <img alt="avatar" :src="getMemberPhoto(worker)" />-->
    <!--                  </v-avatar>-->
    <!--                  <div class="available-worker-name mt-2">-->
    <!--                    {{ worker.last_name }}-->
    <!--                  </div>-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-col>-->
    <!--          </v-row>-->

    <!--          <v-row>-->
    <!--            <v-col cols="12">-->
    <!--              <v-divider></v-divider>-->
    <!--            </v-col>-->
    <!--          </v-row>-->

    <!--          <v-row class="mt-4" align="center">-->
    <!--            <v-col-->
    <!--              cols="1"-->
    <!--              class="text-right employee-list-label text-heading-2"-->
    <!--            >-->
    <!--              夜-->
    <!--            </v-col>-->
    <!--            <v-col cols="11">-->
    <!--              <v-row dense>-->
    <!--                <v-col-->
    <!--                  cols="auto"-->
    <!--                  v-for="(worker, index) in availableNightWorkers"-->
    <!--                  :key="`night-worker-${index}`"-->
    <!--                  class="text-center available-worker-item"-->
    <!--                >-->
    <!--                  <v-avatar size="25">-->
    <!--                    <img alt="avatar" :src="getMemberPhoto(worker)" />-->
    <!--                  </v-avatar>-->
    <!--                  <div class="available-worker-name mt-2">-->
    <!--                    {{ worker.last_name }}-->
    <!--                  </div>-->
    <!--                </v-col>-->
    <!--              </v-row>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </div>-->
    <v-row>
      <v-col cols="2" class="form-header mt-1 text-right">参加メンバー</v-col>
      <v-col cols="10">
        <v-row align="center">
          <v-col cols="auto">
            <v-checkbox
              class="mt-0"
              hide-details
              label="全員参加"
              :indeterminate="allIndeterminateCheck"
              :value="1"
              v-model="selectedCategory"
              @change="toggleAll()"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              class="mt-0"
              hide-details
              label="一般全員"
              :indeterminate="generalIndeterminateCheck"
              :value="2"
              v-model="selectedCategory"
              @change=";[toggleGeneral(), allToggleCheck()]"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              class="mt-0"
              hide-details
              label="日常清掃全員"
              :indeterminate="cleaningIndeterminateCheck"
              :value="3"
              v-model="selectedCategory"
              @change=";[toggleCleaning(), allToggleCheck()]"
            >
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12">
            <div class="user-type-label text-heading-3">一般社員</div>
            <div class="user-type-label-divider"></div>
          </v-col>
          <validation-provider
            v-slot="{ errors }"
            name="selectedGeneral"
            rules=""
          >
            <v-radio-group
              class="mt-0"
              row
              :error-messages="errors"
              :error="errors.length !== 0"
              :hide-details="errors.length === 0"
            >
              <v-checkbox
                class="mx-2 mt-0"
                v-for="(user, userIndex) in generalUsers"
                :key="`general-user-${userIndex}`"
                :value="user"
                :label="`${user.last_name} ${user.first_name}`"
                v-model="_task.selectedGeneral"
                @change="userTypeToggleCheck('general', user.id, $event)"
              />
            </v-radio-group>
          </validation-provider>
        </v-row>

        <v-row align="center">
          <v-col cols="12">
            <div class="user-type-label text-heading-3">日常清掃員</div>
            <div class="user-type-label-divider"></div>
          </v-col>
          <validation-provider
            v-slot="{ errors }"
            name="selectedCleaning"
            rules=""
          >
            <v-radio-group
              class="mt-0"
              row
              :error-messages="errors"
              :error="errors.length !== 0"
              :hide-details="errors.length === 0"
            >
              <v-checkbox
                class="mx-2 mt-0"
                v-for="(user, userIndex) in cleaningUsers"
                :key="`cleaning-user-${userIndex}`"
                :value="user"
                :label="`${user.last_name} ${user.first_name}`"
                v-model="_task.selectedCleaning"
                @change="userTypeToggleCheck('cleaning', user.id, $event)"
              />
            </v-radio-group>
          </validation-provider>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="3" md="2" order="1" class="form-header text-right">リーダー</v-col>
      <v-col cols="9" md="3" order="2">
        <validation-provider v-slot="{ errors }" name="leader" rules="">
          <v-select
            dense
            outlined
            :error-messages="errors"
            :error="errors.length !== 0"
            :hide-details="errors.length === 0"
            :items="selectedUsers"
            item-text="full_name"
            item-value="id"
            v-model="_task.leader_id"
            placeholder="選択"
            class="form-text"
            :style="selectedUsers.length < 2 ? `pointer-events: none` : ''"
          ></v-select>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="3" md="2" order="3" class="form-header text-right">車</v-col>
      <v-col cols="9" md="3" order="4" class="">
        <validation-provider v-slot="{ errors }" name="vehicle" rules="">
          <v-select
            dense
            outlined
            :error-messages="errors"
            :error="errors.length !== 0"
            :hide-details="errors.length === 0"
            :items="_allCars"
            item-text="name"
            item-value="id"
            v-model="_task.car_id"
            placeholder="選択"
            class="form-text"
          ></v-select>
        </validation-provider>
      </v-col>
      <v-col cols="3" md="1" order="6" class="form-header text-right">ETC</v-col>
      <v-col cols="9" md="2" order="7" class="">
        <v-btn-toggle class="btn-toggle" v-model="_task.ETC_card">
          <v-btn height="32" :value="1"> 有 </v-btn>

          <v-btn height="32" :value="0"> 無 </v-btn>
        </v-btn-toggle>
        <span @click="_task.ETC_card = null" class="unselect">選択解除</span>
      </v-col>
      <v-col cols="3" md="1" order="8" class="form-header text-right">
        ガソリンカード
      </v-col>
      <v-col cols="9" md="2" order="9" class="">
        <v-btn-toggle
          class="btn-toggle"
          v-model="_task.gasoline_card"
        >
          <v-btn height="32" :value="1"> 有 </v-btn>

          <v-btn height="32" :value="0"> 無 </v-btn>
        </v-btn-toggle>
        <span @click="_task.gasoline_card = null" class="unselect">選択解除</span>
      </v-col>
      <v-col cols="12" offset="3" offset-md="2" class="py-0" order="5" order-md="12">
        <a @click="showCarAvailabilityModal">空き車両の確認</a>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2" class="mt-6 form-header text-right">外注先</v-col>
      <v-col cols="10">
        <v-row align="center">
          <template v-for="(partner, partnerIndex) in allPartners">
            <v-col
              cols="auto"
              :key="`outsource-staff-${partnerIndex}`"
              class="text-center"
            >
              <v-checkbox :value="partner" v-model="_task.selectedPartners">
                <template v-slot:label>
                  <v-avatar v-if="partner.profile_image" size="40" class="mr-2">
                    <img
                      alt="avatar"
                      :src="getPartnerProfilePicture(partner)"
                    />
                  </v-avatar>
                  {{ partner.name }}
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              style="max-width: 80px"
              :key="`outsource-staff-${partnerIndex}-select`"
              v-if="_task.selectedPartners.find(item => item.id === partner.id)"
            >
              <v-select
                class="form-text outsource"
                outlined
                hide-details="auto"
                dense
                @change="updateTotalFreelanceForPartner($event, partner)"
                :items="freelancersCount"
              ></v-select>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {debounce} from 'lodash'
import CarAvailability from "@/components/dialog/CarAvailabilityModal";

export default {
  name: 'Transportation',
  components: {CarAvailability},
  props: {
    task: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      selectedCategory: [],
      loadAvailableWorkers: false,
      availableWorkersLoaded: false,
      autoLoadAvailableWorkerError: false,
      carAvailabilityModal: {
        visibility: false,
        loading: false
      }
    }
  },
  computed: {
    ...mapGetters(['allUsers', 'allCars', 'allPartners', 'availableUsers']),

    _allCars() {
      return this.allCars.map(car => {
        return { ...car, name: car.car_class + ' ' + car.number_plate }
      })
    },

    _task: {
      get() {
        return this.task
      },
      set(newValue) {
        this.$emit('update:task', newValue)
      }
    },

    allIndeterminateCheck() {
      let allUsers = [...this.generalUsers, ...this.cleaningUsers]
      let selectedUsers = [
        ...this.task.selectedGeneral,
        ...this.task.selectedCleaning
      ]
      return (
        allUsers.length !== selectedUsers.length &&
        selectedUsers.length !== 0 &&
        !this.selectedCategory.includes(1)
      )
    },

    generalIndeterminateCheck() {
      return (
        this.generalUsers.length !== this.task.selectedGeneral.length &&
        this.task.selectedGeneral.length !== 0 &&
        !this.selectedCategory.includes(2)
      )
    },

    cleaningIndeterminateCheck() {
      return (
        this.cleaningUsers.length !== this.task.selectedCleaning.length &&
        this.task.selectedCleaning.length !== 0 &&
        !this.selectedCategory.includes(3)
      )
    },

    generalUsers() {
      return this.allUsers?.filter(user => user.employee_type === 0)
    },

    cleaningUsers() {
      return this.allUsers?.filter(user => user.employee_type === 1)
    },

    workerListLabel() {
      return this.task.date
        ? `${this.task.date}の空きメンバー`
        : '特定日の空き状況を確認するには日付を選択してください。'
    },

    selectedUsers() {
      return [...this.task.selectedGeneral, ...this.task.selectedCleaning]
    },

    freelancersCount() {
      let i = []
      for (let j = 1; j <= 9; j++) {
        i.push(j)
      }
      return i
    },

    type1Partners() {
      return this.allPartners.filter(partner => {
        return partner.type === 1
      })
    },

    availableDayWorkers() {
      return this.availableUsers.filter(user => {
        if (!user.off_days.length) return user

        let offdays = user.off_days.filter(
          offday => offday.date === this.task.date && offday.day_status === 2
        )
        return !offdays.length ? user : false
      })
    },

    availableNightWorkers() {
      return this.availableUsers.filter(user => {
        if (!user.off_days.length) return user

        let offdays = user.off_days.filter(
          offday => offday.date === this.task.date && offday.night_status === 2
        )
        return !offdays.length ? user : false
      })
    }
  },
  watch: {
    '_task.date': {
      deep: true,
      handler() {
        this.getAvailableWorker()
      }
    },
    selectedUsers(newVal) {
      if (newVal?.length === 1) {
        this._task.leader_id = newVal[0].id
      } else {
        this._task.leader_id = null
      }
    }
  },
  methods: {
    async getUserList() {
      await this.getGeneralUser('通常業務')
      await this.getDailyCleaning('日常清掃員')
    },
    async getGeneralUser(type) {
      await this.$store.dispatch('GET_GENERAL_USER', type)
    },
    async getDailyCleaning(type) {
      await this.$store.dispatch('GET_DAILY_CLEANING_USER', type)
    },
    async getAvailableWorker() {
      this.availableWorkersLoaded = false
      let params = {
        date: this.task.date
      }
      this.$store
        .dispatch('USER_GET_ALL_AVAILABLE_WORKER', params)
        .then(() => {
          this.availableWorkersLoaded = true
        })
        .catch(error => {
          this.loadAvailableWorkers = false
          this.autoLoadAvailableWorkerError = true
        })
      // this.resetAutoLoadAvailableWorker()
    },

    toggleAll() {
      if (this.selectedCategory.includes(1)) {
        this._task.selectedGeneral = this.generalUsers
        this._task.selectedCleaning = this.cleaningUsers
        this.selectedCategory.push(2)
        this.selectedCategory.push(3)
        return
      }
      this._task.selectedGeneral = []
      this._task.selectedCleaning = []
      this.selectedCategory = []
    },

    toggleGeneral() {
      if (this.selectedCategory.includes(2)) {
        this._task.selectedGeneral = this.generalUsers
        return
      }
      this._task.selectedGeneral = []
    },

    toggleCleaning() {
      if (this.selectedCategory.includes(3)) {
        this._task.selectedCleaning = this.cleaningUsers
        return
      }
      this._task.selectedCleaning = []
    },

    userTypeToggleCheck(type, id, list) {
      let val
      let comparator
      let category
      if (type === 'general') {
        val = this._task.selectedGeneral.length
        comparator = this.generalUsers.length
        category = 2
      } else {
        val = this._task.selectedCleaning.length
        comparator = this.cleaningUsers.length
        category = 3
      }

      if (val === comparator) {
        if (!this.selectedCategory.includes(category)) {
          this.selectedCategory.push(category)
        }
      } else {
        if (this.selectedCategory.includes(category)) {
          let idx = this.selectedCategory.indexOf(category)
          this.selectedCategory.splice(idx, 1)
        }
      }

      if (id === this.task.leader_id && !list.find(i => i.id === id)) {
        this.task.leader_id = null
      }

      this.allToggleCheck()
    },

    allToggleCheck() {
      let selectedLength =
        this._task.selectedGeneral.length + this._task.selectedCleaning.length
      let totalLength = this.generalUsers.length + this.cleaningUsers.length

      if (selectedLength === totalLength) {
        if (!this.selectedCategory.includes(1)) {
          this.selectedCategory.push(1)
        }
      } else {
        if (this.selectedCategory.includes(1)) {
          let idx = this.selectedCategory.indexOf(1)
          this.selectedCategory.splice(idx, 1)
        }
      }
    },

    updateTotalFreelanceForPartner(e, partner) {
      let find = this._task.selectedPartners.find(p => p.id === partner.id)
      if (find) find.freelancers_count = e
    },

    getMemberPhoto(member) {
      if (member.profile_image) return `${member.profile_image_url}`
      return require('@/assets/images/user-placeholder.png')
    },

    getPartnerProfilePicture(partner) {
      if (partner?.profile_image) return `${partner.profile_image_url}`
      return require('@/assets/images/user-placeholder.png')
    },

    changeAvailableWorkerAutoLoadState() {
      this.loadAvailableWorkers = true
      if (this.task.date) this.getAvailableWorker()
    },

    resetAutoLoadAvailableWorker: debounce(function() {
      this.loadAvailableWorkers = false
    }, 5000),

    async showCarAvailabilityModal() {
      this.carAvailabilityModal.visibility = true
      this.carAvailabilityModal.loading = true
      await this.$store.dispatch('CAR_GET_AVAILABILITY', { date: this.task.date })
      this.carAvailabilityModal.loading = false
    },

    selectCar(id) {
      this._task.car_id = parseInt(id)
    }
  },
  created() {
    if (this.task.date) this.getAvailableWorker()
  }
}
</script>

<style lang="scss" src="./Transportation.scss" scoped></style>
