var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('v-container', [_c('v-row', {
    staticClass: "py-4"
  }, [_c('v-col', {
    staticClass: "text-center text-heading-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('v-btn', {
    staticClass: "close-button",
    attrs: {
      "depressed": "",
      "fab": "",
      "color": "#E27C7C",
      "width": "20",
      "height": "20"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close-dialog');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)]), _c('v-col', {
    staticClass: "text-center pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "image_preview mb-2",
    attrs: {
      "src": _vm.preview_file,
      "width": "100%",
      "height": "100%"
    }
  })])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }