var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.project ? _c('div', [_vm.serviceType === 'coating' ? [_c('div', {
    staticClass: "info-box mt-5 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v(" 作業情報 ")], 1), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "info-box-work-information-headline top",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.coatingGenre) + " "), _c('span', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.service.svc_coating.coating_area) + " ㎡")])]), _c('v-col', {
    staticClass: "info-box-work-information-headline sub",
    attrs: {
      "cols": "12"
    }
  }, [_vm._l(_vm.coatingRange, function (item, index) {
    return [_vm._v(" " + _vm._s(item.text) + " "), item.id ? [_vm._v("(" + _vm._s(item.text_value) + ")")] : _vm._e(), index !== _vm.coatingRange.length - 1 ? [_vm._v(" / ")] : _vm._e()];
  })], 2), _c('v-col', {
    staticClass: "mt-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "info-box-work-information-content-label pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" 床材の色 ")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content pb-3 d-flex",
    attrs: {
      "cols": "8"
    }
  }, [_vm.service.svc_coating.floor_materials.length !== 0 ? _vm._l(_vm.service.svc_coating.floor_materials, function (floorMaterial, index) {
    return _c('div', {
      key: "coating-color-".concat(index),
      staticClass: "d-flex align-center"
    }, [_c('v-avatar', {
      staticClass: "mr-4",
      staticStyle: {
        "border-radius": "5% !important"
      },
      attrs: {
        "tile": "",
        "min-height": "65",
        "min-width": "100",
        "color": floorMaterial.type === 0 ? floorMaterial.color_code : ''
      }
    }, [floorMaterial.type === 1 ? _c('v-img', {
      attrs: {
        "min-height": "65",
        "min-width": "100",
        "src": floorMaterial.image_url
      }
    }) : _vm._e()], 1), _c('span', {
      staticClass: "mr-4"
    }, [_vm._v(" " + _vm._s(floorMaterial.name) + " "), floorMaterial.id === 9 ? [_vm._v(" ( " + _vm._s(floorMaterial.pivot.custom_value) + " ) ")] : _vm._e()], 2)], 1);
  }) : _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.service.svc_coating.floor_color, function (color, index) {
    return _c('v-col', {
      key: "coating-color-".concat(index),
      staticClass: "d-flex align-center mr-3",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "dot mr-1",
      style: {
        backgroundColor: _vm.getColorObj(color.text, 'floor_color').color
      }
    }), _vm._v(" " + _vm._s(color.text) + " "), color.id ? [_vm._v(" (" + _vm._s(color.text_value) + ") ")] : _vm._e()], 2);
  }), 1)], 2), _vm.coatingFloorType ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("床材種類")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.coatingFloorType) + " " + _vm._s(_vm.service.svc_coating.floor_genre_memo ? ' (' + _vm.service.svc_coating.floor_genre_memo + ')' : '') + " ")])] : _vm._e(), _vm.toughSealer ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("タフシーラー")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.toughSealer) + " ")])] : _vm._e(), _vm.removalWork ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("剥離作業")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.removalWork) + " ( " + _vm._s(_vm.memo) + " ) ")])] : _vm._e(), _vm.microMop ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("マイクロモップ")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.microMop) + " ")])] : _vm._e(), _vm.premiumCleaner ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("プレミアム専用クリーナー")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.premiumCleaner) + " ")])] : _vm._e(), _vm.counterTable ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("多目的カウンター ")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.counterTable) + " ")])] : _vm._e(), _vm.assignedPartner ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("外部発注")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.assignedPartner) + " ")])] : _vm._e(), _vm.assignedPartner && _vm.partnerWorkrange ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("発注範囲")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content pb-3 white-space-preline",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.partnerWorkrange) + " ")])] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1)] : _vm._e(), _vm.serviceType === 'coating' ? [_vm.service.svc_coating.premium_guard.length ? _c('div', {
    staticClass: "info-box mt-5 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v("プレミアムガード ")], 1), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', _vm._l(_vm.service.svc_coating.premium_guard, function (premiumGuard) {
    return _c('v-col', {
      key: premiumGuard.text,
      staticClass: "info-box-work-information-headline sub",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(premiumGuard.text) + " "), premiumGuard.text_value ? [_vm._v(" (" + _vm._s(premiumGuard.text_value) + ") ")] : _vm._e()], 2);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.service.svc_coating.campaign ? _c('div', {
    staticClass: "info-box mt-5 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v(" キャンペーンサービス ")], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-work-information-headline sub",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.service.svc_coating.campaign) + " ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.service.svc_coating.coating_option.length ? _c('div', {
    staticClass: "info-box mt-5 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v(" サービス施工 ")], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', _vm._l(_vm.service.svc_coating.coating_option, function (item, index) {
    return _c('v-col', {
      key: "coating-option-".concat(index),
      staticClass: "info-box-work-information-headline sub"
    }, [_vm._v(" " + _vm._s(item.text) + " "), item.text_value ? [_vm._v(" (" + _vm._s(item.text_value) + ") ")] : _vm._e()], 2);
  }), 1)], 1)], 1)], 1) : _vm._e()] : _vm._e(), _vm.serviceType === 'building' ? _vm._l(_vm.serviceData, function (serviceDataFields) {
    return _c('v-container', {
      key: "service-data-".concat(serviceDataFields.name),
      staticClass: "info-box mt-5 pa-8"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "info-box-headline text-heading-2",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "color": "#4F55A7",
        "size": "6"
      }
    }, [_vm._v("$dot")]), _c('span', {
      domProps: {
        "innerHTML": _vm._s(serviceDataFields.label)
      }
    })], 1), _c('v-col', {
      staticClass: "flex-grow-1",
      attrs: {
        "cols": "9"
      }
    }, _vm._l(serviceDataFields.fields, function (field) {
      return _c('v-row', {
        key: "field-".concat(field.name),
        attrs: {
          "align": "center"
        }
      }, [field.name === 'divider' ? [_c('v-divider', {
        staticClass: "mx-3 my-4"
      })] : [field.label == 'ワックス塗布階数' ? _c('v-col', {
        staticClass: "info-box-work-information-content-label text-heading-3",
        attrs: {
          "cols": "2"
        }
      }, [_vm._v(" 範囲指定 ")]) : _vm._e(), field.label == 'ワックス塗布階数' ? _c('v-col', {
        staticClass: "info-box-work-information-content-content text-heading-3",
        attrs: {
          "cols": "10"
        }
      }, [_c('span', {
        staticStyle: {
          "line-height": "2"
        },
        domProps: {
          "innerHTML": _vm._s(_vm.getWixingValue(_vm.project_scv.svc_floor))
        }
      })]) : _vm._e(), field.label != 'ワックス塗布階数' ? _c('v-col', {
        staticClass: "info-box-work-information-content-label text-heading-3",
        attrs: {
          "cols": "2"
        }
      }, [_vm._v(" " + _vm._s(field.label) + " ")]) : _vm._e(), field.label != 'ワックス塗布階数' ? _c('v-col', {
        staticClass: "info-box-work-information-content-content text-heading-3",
        attrs: {
          "cols": "10"
        }
      }, [Array.isArray(_vm.service["svc_".concat(serviceDataFields.name)][field.name]) ? [_c('v-row', _vm._l(_vm.service["svc_".concat(serviceDataFields.name)][field.name], function (data, index) {
        return _c('v-col', {
          key: "data-".concat(field.name, "-").concat(index),
          class: [field.type === 'color' ? 'd-flex align-center mr-3' : ''],
          attrs: {
            "cols": "auto"
          }
        }, [field.type === 'color' ? [_c('span', {
          staticClass: "dot mr-1",
          style: {
            backgroundColor: _vm.getColorObj(data.text, field.name).color
          }
        })] : _vm._e(), _vm._v(" " + _vm._s(data.text) + " ")], 2);
      }), 1)] : _vm._e(), _vm.isObjectOrString(_vm.service["svc_".concat(serviceDataFields.name)][field.name]) ? [_c('v-row', [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_vm._v(" " + _vm._s(_vm.service["svc_".concat(serviceDataFields.name)][field.name]) + " ")])], 1)] : _vm._e()], 2) : _vm._e()]], 2);
    }), 1)], 1)], 1);
  }) : _vm._e()], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }