<template>
  <div class="mt-4 pa-8">
    <v-row>
      <v-dialog
        ref="showPhotoDialog"
        v-model="showPhotoDialog"
        width="70%"
        @click:outside="showPhotoDialog = false"
      >
        <ShowPhotoDialog
          :value.sync="showPhotoDialog"
          :photo="photo"
          @close-dialog="showPhotoDialog = false"
        ></ShowPhotoDialog>
      </v-dialog>
      <v-col cols="2" class="info-box-headline text-heading-2"> 写真 </v-col>
      <v-col cols="12" class="text-center" v-if="loading">
        <v-progress-circular size="32" indeterminate />
      </v-col>
    </v-row>
    <template v-if="images.length === 0" class="mt-4 pt-6">
      <v-row>
        <v-col cols="12" class="text-center">
          写真がありません。
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row v-for="item in images" :key="item.id" align="center">
        <v-col class="col-3">
          <v-img
              max-height="200"
              contain
            @click="showPhoto(item.id)"
            :src="item.path ? item.path_url : `${root}images/no-image.jpg`"
          ></v-img>
        </v-col>
        <v-col cols="6" class="photo-desc">
          <v-row>
            <v-col cols="12" class="pb-0">
              <span class="mr-3">{{ getDateFormat(item.date) }}</span>
              {{ item.updated_by_user ? item.updated_by_user.full_name : '-' }}
            </v-col>
            <v-col cols="12 text-heading-3">
              {{ item.memo }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/plugins/dayjs'
import ShowPhotoDialog from '@/components/admin/partials/Task/View/CustomerComponents/ShowPhotoDialog'
export default {
  name: 'Photos',
  components: { ShowPhotoDialog },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      root: process.env.VUE_APP_BASEURL,
      showPhotoDialog: false,
      photo: null
    }
  },
  filters: {
    formatDate(value) {
      return dayjs(value).format('YYYY/M/D')
    }
  },
  computed: {
    ...mapGetters(['task']),
    images() {
      return this.task.photos.slice(0, 5)
    },
    imageBaseUrl() {
      return process.env.VUE_APP_BASEURL
    }
  },
  methods: {
    getDateFormat(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleDateString('ja-JP', options)
    },
    showPhoto(id) {
      this.photo = this.images.find(c => c.id == id)
      this.showPhotoDialog = true
    }
  }
}
</script>

<style lang="scss" src="./Photos.scss" scoped></style>
